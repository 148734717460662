<template>
  <BaseModal
    :modal-name="accountModal"
    :title="accountModalComponent[currentComponent].title"
    :modal-width="accountModalComponent[currentComponent].width"
  >
    <component
      :is="accountModalComponent[currentComponent].component()"
      @change-component="onChangeComponent"
    />
  </BaseModal>
</template>

<script setup>
import SignIn from "~/modules/account/components/sign/SignIn.vue";
import SignUp from "~/modules/account/components/sign/SignUp.vue";
import ResetPassword from "~/modules/account/components/sign/ResetPassword.vue";
import {
  useModalStore,
  accountModal,
} from "~/modules/shared/modals/store/modalStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const emits = defineEmits(["close"]);

const modalStore = useModalStore();
const { getExtra: extra } = storeToRefs(modalStore);

const currentComponent = ref(null);

currentComponent.value = extra.value(accountModal).component;

const accountModalComponent = {
  signIn: {
    component: () => SignIn,
    title: _T("@Entrance"),
    width: 700,
  },
  signUp: {
    component: () => SignUp,
    title: _T("@Registration"),
    width: 1044,
  },
  passwordReset: {
    component: () => ResetPassword,
    title: _T("@Password reset"),
    width: 500,
  },
};

function onChangeComponent(component) {
  currentComponent.value = component;
}
</script>
