<template>
  <div class="sign-up__w">
    <img class="sign-up__logo" src="/img/signUp-cat.svg" alt="sign up logo" />
    <form class="sign-up" @submit.prevent="onSignUp">
      <div class="sign-up__body">
        <InputText
          v-model="form.firstName"
          :validate="v.firstName"
          :caption="_T('@Name')"
          :autocomplete-type="'given-name'"
        />
        <InputText
          v-model="form.lastName"
          :validate="v.lastName"
          :caption="_T('@Last name')"
          :autocomplete-type="'family-name'"
        />
        <InputPhone
          v-model="form.phone"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputMail
          v-model="form.email"
          :validate="v.email"
          :caption="'E-mail'"
        />
        <Password
          v-model="form.password"
          :validate="v.password"
          :caption="_T('@Password')"
          autocomplete-type="'new-password'"
        />
        <Password
          v-model="form.confirmedPassword"
          :validate="v.confirmedPassword"
          :caption="_T('@Password confirmation')"
          autocomplete-type="'new-password'"
        />
      </div>
      <label
        class="sign-up__agree"
        :class="{ error: v.agreement.$invalid && v.agreement.$dirty }"
      >
        <input v-model="form.agreement" type="checkbox" />
        <span class="sign-up__caption">
          <span
            >{{ _T("@By registering, you agree to") }}
            <MyLink
              class="sign-up__caption-link"
              name="privacy-policy"
              target="_blank"
            >
              {{ _T("@By privacy policy") }}
            </MyLink>
          </span>
        </span>
      </label>
      <div class="sign-up__footer">
        <div v-if="isForbiddenError" class="sign-up__footer-error">
          <p class="sign-up__footer-error-text">
            {{ _T(forbiddenErrorMessage) }}
          </p>
        </div>
        <ButtonPrimary
          button-width="268px"
          type="submit"
          :disabled="signUpHandler.isHandled"
        >
          {{ _T("@Registration") }}
        </ButtonPrimary>
        <div
          class="sign-up__footer-button"
          @click.prevent="changeComponent('signIn')"
        >
          {{ _T("@Im already registered") }}
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import Password from "~/modules/shared/inputs/Password.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputPhone from "~/modules/shared/inputs/InputPhone.vue";
import InputMail from "~/modules/shared/inputs/InputMail.vue";
import { formatPhone } from "~/utils/phoneHelper";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import {
  successfulModal,
  accountModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  minLengthValidate,
  phoneValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import forbidden from "~/config/forbidden.config";
import { useUserStore } from "~/store/userStore";
import { signFormStore } from "~/modules/account/store/accountFormStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";

const emits = defineEmits(["changeComponent"]);

const languageStore = useLanguageFilterStore();
const { getUserLanguage: lang } = storeToRefs(languageStore);

const formStore = signFormStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const successfulData = {
  title: _T("@Registration"),
  description: _T("@Registration completed successfully"),
  isWithTimeout: true,
};

const isForbiddenError = ref(false);
const forbiddenErrorMessage = ref("");

const form = reactive({
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  confirmedPassword: "",
  agreement: true,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  lastName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  password: {
    required: requiredValidate(),
    minLength: minLengthValidate("@Minimum password length", 6),
    validatePassword: regExpValidate(
      "@The password must contain at least one number",
      /\d/,
    ),
  },
  confirmedPassword: {
    required: requiredValidate(),
    sameAsPassword: sameAsValidate("@Password mismatch", form.password),
  },
  agreement: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function requestOptions() {
  return {
    email: form.email,
    phone: formatPhone(form.phone),
    password: form.password,
    name: {
      firstName: form.firstName,
      lastName: form.lastName,
    },
    locale: lang.value.name,
  };
}

const signUpHandler = useSingletonHandler(() =>
  useAPI("/account/sign/up", {
    method: "POST",
    body: {
      ...formStore.formRequestOptions,
    },
  }),
);

async function onSignUp() {
  if (formStore.isFormsReady()) {
    try {
      await signUpHandler.handle();

      await userStore.fetch();

      modalStore.toggleModal(accountModal);
      modalStore.toggleModal(successfulModal, successfulData);
    } catch (error) {
      isForbiddenError.value = true;
      if (forbidden.ACCOUNT_EMAIL_ALREADY_EXISTS === error.data.code) {
        forbiddenErrorMessage.value = "@Account email already exists";
      }

      if (forbidden.ACCOUNT_PHONE_ALREADY_EXISTS === error.data.code) {
        forbiddenErrorMessage.value = "@Account phone already exists";
      }
    }
  }
}

function changeComponent(component) {
  emits("changeComponent", component);
}

onMounted(() => {
  formStore.addForm({ v, requestOptions, id: "SignUp" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.sign-up {
  width: 100%;

  @include flex-container(column, center, center);
  gap: 24px;

  &__w {
    width: 100%;

    @include flex-container(row, center, center);
    gap: 24px;
  }

  &__logo {
    @include bigMobile {
      display: none;
    }
  }

  &__body {
    width: 100%;

    @include flex-container(row, center, center);
    flex-wrap: wrap;
    gap: 24px 16px;
  }

  &__footer {
    width: 100%;

    @include flex-container(column, center, center);
    gap: 16px;
  }

  &__footer-button {
    @include font(16, 20);
    color: var(--color-primary-base);

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__agree {
    position: relative;

    @include mobile {
      max-width: 363px;
    }

    & input {
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;

      opacity: 0;
      visibility: hidden;
    }

    & .sign-up__caption {
      @include flex-container(row, space-between, center);
      gap: 8px;

      user-select: none;

      &::before {
        content: "";
        display: inline-block;

        width: 24px;
        height: 24px;

        flex: 0 0 24px;

        border: 1px solid var(--color-sky-base);
        border-radius: 4px;

        background-repeat: no-repeat;
        background-position: center;

        transition:
          border-color 0.2s ease,
          background 0.2s ease;
      }
    }

    &.error > .sign-up__caption::before {
      border-color: #f73b2f;
    }

    & input:not(:checked) + .sign-up__caption:hover::before {
      border-color: var(--color-primary-base);
    }

    & input:checked + .sign-up__caption::before {
      border-color: var(--color-primary-base);
      background-color: var(--color-primary-base);
      background-image: url("~~/assets/icons/check-icon.svg");
    }
  }

  &__caption {
    @include font(16, 20, 400);

    @include mobile {
      @include font(12, 16);
    }
  }

  &__caption-link {
    color: var(--color-primary-base);
    white-space: nowrap;

    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &__footer-error-text {
    @include font(16, 24);

    color: #fb2424;
  }
}
</style>
