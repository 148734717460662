<template>
  <BaseModal :title="successfulData.title" :modal-name="successfulModal">
    <div class="success-modal">
      <div class="success-modal__body-img-w">
        <img src="/img/successful-form.svg" alt="successful form" />
      </div>
      <p class="success-modal__body-text">{{ successfulData.description }}</p>
    </div>
  </BaseModal>
</template>

<script setup>
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const modalStore = useModalStore();
const successfulData = storeToRefs(modalStore).getExtra.value(successfulModal);

onMounted(() => {
  if (successfulData.isWithTimeout) {
    setTimeout(() => {
      modalStore.toggleModal(successfulModal);
    }, 5000);
  }
});
</script>

<style lang="scss" scoped>
.success-modal {
  @include flex-container(column, center, center);
  gap: 24px;

  &__body-img-w {
    width: 84px;
    height: 84px;

    @include flex-container(row, center, center);

    border: 2px solid var(--color-primary-base);
    border-radius: 50%;
  }

  &__body-text {
    @include font(18, 24, 500);
    text-align: center;
  }
}
</style>
