<template>
  <BaseModal
    :title="
      product.status === 'preOrder'
        ? _T('@Product pre-order')
        : _T('@Report availability')
    "
    :modal-name="preOrderModal"
  >
    <div class="modal-preorder__body">
      <div class="modal-preorder__select-item">
        <div class="modal-preorder__img">
          <img
            :src="product.getMainImage('small')"
            :alt="cleanStringsConcat([product.getName(), 'image'])"
          />
        </div>
        <div class="modal-preorder__item-info">
          <p class="modal-preorder__item-name">
            {{ product.getName() }}
          </p>
          <p class="modal-preorder__item-code">
            {{ `${_T("@Code")} ${product.code}` }}
          </p>
        </div>
      </div>

      <form action="" class="modal-preorder__form">
        <div class="modal-preorder__inputs">
          <InputText
            v-model="form.firstName"
            :validate="v.firstName"
            :caption="_T('@Name')"
            :maxlength="255"
            :autocomplete-type="'given-name'"
          />
          <InputPhone
            v-model="form.phone"
            :validate="v.phone"
            :caption="_T('@Phone number')"
          />
          <InputMail
            v-model="form.email"
            :validate="v.email"
            :caption="'E-mail'"
          />
        </div>

        <div class="modal-preorder__checkbox-wrp">
          <label
            class="modal-preorder__agree"
            :class="{ error: v.isAgree.$invalid && v.isAgree.$dirty }"
          >
            <input v-model="form.isAgree" type="checkbox" />
            <span class="modal-preorder__caption">
              <span
                >{{ _T("@I agree with") }}
                <MyLink
                  class="modal-preorder__caption-link"
                  name="delivery"
                  target="_blank"
                >
                  {{ _T("@terms of delivery") }}
                </MyLink>
                {{ _T("@and") }}
                <MyLink
                  class="modal-preorder__caption-link"
                  name="privacy-policy"
                  target="_blank"
                >
                  {{ _T("@privacy policy") }}
                </MyLink>
              </span>
            </span>
          </label>
        </div>
        <ButtonProductSubscribe
          :status="product.status"
          :disable="subscribeHandler.isHandled"
          @click.prevent="onSubscribe"
        />
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputMail from "~~/modules/shared/inputs/InputMail.vue";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputPhone from "~~/modules/shared/inputs/InputPhone.vue";
import ButtonProductSubscribe from "~/modules/shared/buttons/ButtonProductSubscribe.vue";
import { formatPhone } from "~~/utils/phoneHelper";
import {
  preOrderModal,
  successfulModal,
  useModalStore,
} from "~~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  phoneValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const modalStore = useModalStore();
const product = storeToRefs(modalStore).getExtra.value(preOrderModal);

const formStore = modalFormStore();

const userStore = useUserStore();
const { currentUser: user } = storeToRefs(userStore);

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  firstName: "",
  phone: "",
  email: "",
  isAgree: true,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  isAgree: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const subscribeHandler = useSingletonHandler(() =>
  useAPI("/catalog/product/subscription/add", {
    method: "POST",
    body: {
      productId: product.id,
      type: product.status === "preOrder" ? "preOrder" : "order",
      email: form.email,
      phone: formatPhone(form.phone),
      name: form.firstName,
    },
  }).then(() => {
    modalStore.toggleModal(preOrderModal);

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

async function onSubscribe() {
  if (formStore.isFormsReady()) {
    await subscribeHandler.handle();
  }
}

onMounted(() => {
  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.phone = user.value.phone;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "preorderSubscription" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-preorder {
  &__body {
    @include flex-container(column, space-between);

    gap: 24px;
  }

  &__select-item {
    @include flex-container(row, space-between, center);

    gap: 16px;
  }

  &__img {
    width: 90px;
    height: 90px;

    flex: 0 0 auto;
  }

  &__item-info {
    @include flex-container(column, space-between);

    gap: 8px;
  }

  &__item-name {
    @include font(16, 22, 600);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__item-code {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__form {
    @include flex-container(column, space-between);

    gap: 24px;
  }

  &__inputs {
    @include flex-container(column, space-between, center);

    gap: 24px;
  }

  &__checkbox-wrp {
    padding: 0 32px;
  }

  &__agree {
    position: relative;

    & input {
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;

      opacity: 0;
      visibility: hidden;
    }

    & > span {
      @include flex-container(row, space-between, center);
      gap: 16px;

      user-select: none;
    }

    & > span::before {
      content: "";
      display: inline-block;

      width: 24px;
      height: 24px;

      flex: 0 0 24px;

      border: 1px solid var(--color-sky-base);
      border-radius: 4px;

      background-repeat: no-repeat;
      background-position: center;

      transition: 0.2s ease;
    }

    &.error > span::before {
      border-color: #f73b2f;
    }

    & input:not(:checked) + span:hover::before {
      border-color: var(--color-blue-700);
    }

    & input:checked + span::before {
      border-color: var(--color-blue-700);
      background-color: var(--color-blue-700);
      background-image: url("~~/assets/icons/check-icon.svg");
    }
  }

  &__caption {
    @include font(12, 16);
  }

  &__caption-link {
    color: var(--color-blue-700);
  }
}
</style>
